@import 'var';

$height : 44px;
$moHeight : 45px;

.cs-input, .cs-input-date, .cs-input-select-text, .cs-box-input{
  font-size: 14px;
  border:1px solid #dddddd;
  vertical-align: middle;
  height: $height;box-sizing: border-box;padding:0 15px;
  border-radius:4px;
  color: #2d2d2d;
  &::placeholder{color: #848484;font-weight: 300;}
  :disabled, &.disabled{
    background-color: #eeeeee;
    border-color: #e1e1e1;
  }
}
.js__file-upload{
  input[type="file"]{
    position: absolute;left: -99999em;
  }
  &.type2{
    input[type="text"]{
      width: calc(100% - 90px);border-right: 1px solid #d9d9d9;
    }
    .btn-file{
      width: 80px;min-width: auto;margin-left: 10px;
    }
  }
}

.input{
  display: inline-block;width: 100%;padding-left: 15px;
  height: 30px;border: 1px solid #d9d9d9;
}
.select{
  display: inline-block;width: 100%;padding-left: 15px;
  background-color: #fff;
  height: 35px;border: 1px solid #d9d9d9;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #999999;
    font-size: 16px;
    letter-spacing: -0.025em;
  background: url("#{$image}/select.png") no-repeat right 8px center;
}

.lbl{
  &-checkbox{
    display: inline-block;position: relative;
    vertical-align: top;
    color: #999;
    input[type="checkbox"]{
      position: absolute;left: -9999em;
      opacity: 0.1;
      width: 1px;height: 1px;overflow: hidden;
      &:checked+{
        span{
          background-image: url("#{$image}/icon-checkbox-on.png");
        }
      }
    }
    span{
      display: inline-block;vertical-align: top;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url("#{$image}/icon-checkbox-off.png");
      width: 20px;height: 20px;
      
    }
  }
  &-radio{
    display: inline-block;position: relative;
    vertical-align: top;color: #999;
    input[type="radio"]{
      position: absolute;left: -9999em;
      opacity: 0.1;
      width: 1px;height: 1px;overflow: hidden;
      &:checked+{
        span{
          background-image: url("#{$image}/icon-radio-on.png");
        }
      }
    }
    span{
      display: inline-block;vertical-align: top;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url("#{$image}/icon-radio-off.png");
      width: 15px;height: 15px;

    }
  }
}
.vat{vertical-align: top;}

.lbl-txt{
  display: inline-block;vertical-align: middle;
  input{
    vertical-align: middle;
  }
}
.checkbox-group{
  label{
    +label{
      margin-left: 12px;
    }
    span{
      margin-right: 3px;
    }
  }
  &.per{
    overflow: hidden;
    label{
      width: 20%;
      float: left;
      +label{
        margin-left: 0;
      }
    }
  }
}
.radio-group{
  label{
    vertical-align: middle;
    span{
      vertical-align: middle;margin-right: 3px;
    }
    +label{
      margin-left: 35px;
    }
  }
}

.frm-input{
  font-size: 0;
  >div{
    display: inline-block;
    +div{
      margin-left: 45px;
    }
  }
  label{
    font-size: 14px;height: 30px;line-height: 30px;
    display: inline-block;margin-right: 10px;vertical-align: top;
  }
  input{
    display: inline-block;
  }
}