// *[class$=cs-btn]{cursor: pointer;border: 1px solid #000;}

$height : 44px;
$moHeight : 36px;

.cs-btn-link{text-decoration: none;color:inherit;}
.cs-btn{
  border: 1px solid #c5c5c5;transition:all .3s;
  border-radius:4px;
  text-align: center;
  font-size: 14px; //20px
  padding:0 15px;box-sizing: border-box;height:$height;line-height: #{$height - 2};
  min-width:128px;
  color: #363636;text-decoration: none;display: inline-block;vertical-align: middle;
  cursor: pointer;
}
.tac{text-align: center;}
.cs-btn-ss{
  height: 24px;line-height: 22px;min-width:auto;font-size: 12px;font-weight: 300;padding:0 10px;
}
.cs-btn-x{
  height:56px;box-sizing: border-box;font-size: 16px;border-radius:8px;
}
.cs-btn-black{background: #4e565c;border-color:#4e565c;color: #fff;}
.cs-btn-blue{background:#2a78e1;border-color:#2a78e1;color: #fff;}
.cs-btn-gray{background:#768391;border-color:#768391;color: #fff;}
.cs-btn-info{
  width: 24px;height: 24px;background: #e8e8e8;
  border: 0;
  display: inline-block;border-radius: 50%;
  &::after{content: '?';display: inline-block;position: relative;top: -1px;}
}
[class^=cs-btn]:disabled{background: #cccccc;color: #fff;border:1px solid #cccccc;cursor: default;}
.board-list-foot{
  position: relative;
  .board-btn-group{
    text-align: right;
  }
}

.cs-btn.h35{
  height: 35px;line-height: 35px;
}
.cs-btn.width-100{
 padding: 0;text-align: center;
 min-width:auto;
}

.cs-btn-file{
  width: 130px;height: 35px !important;line-height: 35px !important;padding-left: 27px;padding-right: 0;
  text-align: left;
  background-position: 8px 50%;
  background-repeat: no-repeat;
  background-image: url("#{$image}/btn-icon-file.png");
  font-size: 14px !important;
}


.cs-btn-file2{
  width: 130px;height: 35px !important;line-height: 35px !important;padding-left: 45px;padding-right: 0;
  text-align: left;
  background-position: 25px 50%;
  background-repeat: no-repeat;
  background-image: url("#{$image}/btn-icon-file2.png");
  font-size: 14px !important;
}


.cs-btn-print{
  min-width: auto;
  width: 100px;height: 35px !important;line-height: 35px !important;padding-left: 40px;
  padding-right: 0;
  text-align: left;
  background-position: 8px 50%;
  background-repeat: no-repeat;
  background-image: url("#{$image}/btn-icon-print.png");
  font-size: 14px !important;
}


.cs-btn-adown{
  min-width: auto;
  width: 200px !important;height: 40px !important;line-height: 40px !important;padding-left: 53px;
  padding-right: 0;
  text-align: left;
  background-position: 27px 50%;
  background-repeat: no-repeat;
  background-image: url("#{$image}/btn-icon-filedw.png");
  font-size: 14px !important;
}


.btn-section{
  text-align: center;padding-top: 75px;
  .cs-btn{
    width: 156px;
  }
  &.tar{
    text-align: right;
  }
}

.tabcontent{
  display: none;
}


.cs-btn.cs-btn-blue{line-height: 40px;}
.cs-btn.cs-btn-blue:hover{color: #fff;;}