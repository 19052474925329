.ui-datepicker{
  border:1px solid #636363;background: #fff;
  margin-top: 10px;
  padding:20px 2px 15px 2px;
  width: 250px;box-sizing: border-box;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);

  .ui-datepicker-title{font-size: 13px;color: #202020;margin-left: 12px;}

  .ui-datepicker-prev,
  .ui-datepicker-next{cursor: pointer;position: absolute;right:0;
    background: url('#{$image}/icon_calendar_arr.png');
    font-size: 0;width: 20px;height: 20px;
  }

  .ui-datepicker-prev{right: 45px;}
  .ui-datepicker-next{right: 15px;transform: rotate(180deg);}

  .ui-datepicker-calendar{
    width:100%;
    margin-top: 20px;
    th{color: #7d86a9;font-weight: normal;}

    th, td{font-size: 12px;text-align: center;}
    
    th, td, a{font-weight: 300;}

    td, a{color: #7d86a9;text-decoration: none;}
    a{width: 25px;height: 25px;display: inline-block;line-height: 25px;color:inherit;}

    // .ui-state-highlight{color: red;}
    .ui-state-active{background: #1676ff;color: #fff;border-radius: 50%;}

    // .ui-datepicker-unselectable{color: red;}
    .ui-state-disabled{color: #e2e2e2;}
  }
}