@import 'var';

html, body{margin: 0;padding: 0;}

body
{
  font-weight: 300;
}

a{text-decoration: none;color: #171717;}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display:block; }
table{border-collapse:collapse; vertical-align:top;}
img{border:0;}

input, select, button, textarea{font-size: inherit;font-family:inherit;letter-spacing:inherit;}

table caption{position: absolute;height:1px;overflow: hidden;}

fieldset{border:none;margin:0; padding:0;}

*{box-sizing: border-box;}

strong{font-weight: 500;}