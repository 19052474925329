@charset "utf-8";
@import './include/var';

.tab{
	overflow: hidden;
	a.toggle{
		display: block;
		float: left;
		width: 220px;
		height: 50px;
		border: 1px solid #dddddd;
		border-bottom: 0;
		line-height: 49px;
		text-align: center;
		color: #333333;
		+a{
			margin-left: -1px;
		}
		&.active{
			background-color: #2f71c9;
			border-color: #2f71c9;
			color: #fff;
		}
	}
}