@charset "utf-8";
@import 'include/var';
@import 'include/base';
@import 'include/common';

// @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&family=Roboto:wght@100&display=swap');
@font-face {
  font-family: 'KoPubDotumMedium';
   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/KoPubDotumMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


/* =======================
  reset
======================= */
*{
  box-sizing:border-box;
}
.cf:after, .clear:after {display: block;content: '';clear: both;}
.hide {overflow: hidden;position: absolute;width: 1px;height: 1px;margin: -1px;clip: rect(0 0 0 0);}

/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {margin:0;padding:0;}
fieldset,img {border:0 none}
dl,ul,ol,menu,li {list-style:none}
blockquote, q {quotes:none}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none}
input,select,textarea,button {font-size:100%;vertical-align:middle}
button {border:0 none;background-color:transparent;cursor:pointer}
table {border-collapse:collapse;border-spacing:0}
body {-webkit-text-size-adjust:none;} /* 뷰표트 변환시 폰트크기 자동확대 방지 */
input[type='text'],input[type='password'],input[type='submit'],input[type='search'] {-webkit-appearance:none; border-radius:0}
input:checked[type='checkbox'] {background-color:#666; -webkit-appearance:checkbox}
button,input[type='button'],input[type='submit'],input[type='reset'],input[type='file'] {-webkit-appearance:button; border-radius:0}
input[type='search']::-webkit-search-cancel-button {-webkit-appearance:none}
a {color:#333;text-decoration:none}
a:active, a:hover {text-decoration:none;color:#333;}
address,caption,cite,code,dfn,em,var {font-style:normal;font-weight:normal}

img{vertical-align:top;}

select.select,
body {font-family: 'Noto Sans KR', sans-serif; font-size: 16px; font-weight: 400;}
button {border: 0; background-color: transparent; padding: 0;}
select {border: none;}
h2 {margin: 0;}
p {margin: 0;}

/* =======================
  common
======================= */
@mixin inner {max-width: 1240px; margin: 0 auto;}
$color-point: #1e5caf;

.lay-body{padding-top: 188px;}

// width
.w100 {width: 100% !important;}
.w70 {width: 70% !important;}

//input
.cs-input, .cs-input-date, .cs-input-select-text, .cs-box-input {
  height: 42px;
  border-radius: 0;
  border-color: #cdcdcd;
}
.form-input {
  width: 100%; height: 42px;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}
.js__file-upload {
  .upload-input {
    display: block;
    width: calc(100% - 152px);
    height: 46px;
    border: 1px solid #d2d2d2;
    border-right: 0;
    box-sizing: border-box;
    &:read-only {
      outline: none;
    }
  }
  &> * {float: left;}
}

// btn 
.cs-btn {
  height: 42px;
  border-radius: 3px;
  font-size: 16px;
}
.btn {
  display: inline-block;
  border: 1px solid #dddddd;
  color: #999999;
  text-align: center;
  box-sizing: border-box;
}
.btn {
  &-point {
    border-color: $color-point;
    background-color: $color-point;
    color: #fff;
  }
  &-gray {
    border-color: #999999;
    background-color: #999999;
    color: #fff;
  }
}
.btn-file {
  width: 150px;
  height: 46px; line-height: 46px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #d2d2d2;
  text-align: center;
}

//icon
.icon-doc {
  position: relative;
  &::before {
    content: ''; display: block;
    position: absolute; top: 50%; right: -12px;
    transform: translateY(-50%);
    width: 12px; height: 6px;
    background: url('#{$image}/board/arrow.png');
  }
}

/* =======================
  hedaer
======================= */
.lay-header {
  margin-bottom: 25px;
  position: absolute;left: 0;right: 0;top: 0;background: #fff;
  z-index:300;
  // height: 163px;
  border-bottom: 5px solid $color-point;
  &__tnav {
    @include inner();
    position: relative;
    color: #6b6b6b;
    font-size: 12px;
    text-align: right;
    box-sizing: border-box;
    a {
      color: #333;
      font-size: 12px;
    }
  }
}
.lay-hedaer__gnb{
  @include listRest('ul');
  @include clearBoth('ul');
  margin-top: 25px;
  border-top: 1px solid #c2c5d2;
  > ul {
    @include inner();
    display:flex;
    padding: 0 80px;
    box-sizing: border-box;

    padding: 0;

    //snb
    > li > ul{
      display: none;
      margin-top: 10px;
      padding-bottom: 30px !important;
      // display: none;
      padding:0;

      a{
        font-size: 15px;text-align: center;display: block;font-weight: 300;
        padding: 5px 0;
        &:hover{font-weight: 400;}
      }
    }
  }
  
  > ul > li {
    flex:auto;
    flex-basis: 0;
    position: relative;
    &::before {
      content: ''; display: block;
      position: absolute; top: 22px; left: 0;
      width: 1px; height: 12px;
      background-color: #adadad;
    }
    &:first-child::before {content: none;}
    &.current{
      a{
        color: #fff;font-weight: 500;position: relative;background-color: #1e5caf;
        &:after{width: 100%;}
      }
    }
    
    > a {
      transition:all 0.1s;
      display: block;
      height:56px; line-height: 56px;
      font-size: 18px;
      text-align: center;
      text-decoration:none;
      position: relative;
      &:hover{
        color: #fff;background-color: #1e5caf;
      }
    }
  }
  
}
// .lay-link {
//   position: absolute; right: 0;
//   margin-top: 15px;
//   &::after {display: block;content: '';clear: both;}
//   a {
//     float: left;
//     display: block;
//     position: relative;
//     margin-left: 15px;
//     padding-left: 15px;
//     &::before {
//       content: ''; display: block;
//       position: absolute; top: 3px; left: 0;
//       width: 1px; height: 12px;
//       background-color: #414141;
//     }
//     &:first-child::before {content: none;}
//   }
// }
.lay-wrap {
  padding-top: 42px;
  &::after {display: block;content: '';clear: both;}
}
.site-logo {
  float: left;
  width: 230px; height: 34px;
  background: url('#{$image}/logo.png');
  font-size: 0;
}

.member-nav{
  float: right;
  .login-util{
    line-height: 1;
    .btn-logout{
      border-radius:3px;
      display: inline-block;margin-left: 10px;
      color: #fff;font-size: 12px;
      background-color: #999;
      padding: 7px 12px;
      line-height: 1.2;
      height: auto;
    }
  }
  a {
    display: inline-block;
    height: 34px; line-height: 34px;
    text-decoration: none;
    &:first-child {margin-right: 30px;}
  }
}

/* =======================
  footer
======================= */
.lay-footer {
  padding: 20px 0 26px;
  background-color: #2c2f3c;
  color: #fff;
  font-size: 14px;
  &> div {
    @include inner();
    &> * {float: left;}
  }
  &__logo {
    width: 133px; height: 51px;
    margin-right: 50px;
    background: url('#{$image}/footer-logo.png');
  }
  &__info {
    width: calc(100% - 835px);
    font-size: 11px;
    line-height: 18px;
    opacity: 0.7;
  }
  &__link {
    float: right !important;
    text-align: center;
    &::after {display: block;content: '';clear: both;}
    div {
      float: right;
      &::after {display: block;content: '';clear: both;}
      &> * {
        float: left;
        height: 42px; line-height: 42px;
        margin-left: 20px;
        background-color: #212533;
        color: #fff;
        &:first-child {margin-left: 0;}
      }
    }
    a {
      display: inline-block;
      padding: 0 8px;
    }
    select {
      width: 180px;
      padding: 0 20px;
      background-image: url('#{$image}/footer-select.png');
      background-repeat: no-repeat;
      background-position: 140px center;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
}


/* =======================
  sub
======================= */
.lay-sub {position: relative;}
//subvisual
.lay-subvisual{
  height: 150px;
  padding-top: 30px;
  background:#e9edf6;
  text-align: center;
  box-sizing: border-box;
  h2 {
    position: relative;
    display: block;
    margin-bottom: 15px;
    font-size: 25px;
    &::before {
      content: '';
      position: absolute; top: 12px; left: 50%;
      transform: translateX(-50%);
      width: 120px; height: 20px;
      border-radius: 30px;
      background-color: #c4d3e9;
    }
    span {
      position: relative;
      display: block;
      width: 100%;
    }
  }
  p {font-size: 15px;}
}
//navi
.sub-navi {
  &--bg {
    position: absolute; top: 120px;
    width: 100%; height: 64px;
    background: linear-gradient(to right, transparent 40%, #1e5caf 1px);
  }
  &__wrap {
    @include inner();
    position: relative;
  }
  position: absolute; top: -30px;
  width: 100%;
  background-color: $color-point;
  &::after {display: block;content: '';clear: both;}
  &> * {
    float: left;
    color: #fff;
  }
  &> div {
    padding: 22px 0px;
    padding-left: 32px;
    &> * {
      float: left;
      color: #fff;
      margin-right: 15px;
    }
  }
  .home {
    width: 60px; height: 63px;
    background: no-repeat url('#{$image}/home.png');;
  }
  &__arrow {background: no-repeat center url('#{$image}/board/paging-prev.png');}
}
//navi
.navi {
  @include listRest('ul');
  @include clearBoth('ul');
  @include inner();
  &::after {display: block;content: '';clear: both;}
  ul {float: right;}
  li {
    float: left;
    font-size: 12px;
    &:not(:last-child) {
      position: relative;
      margin-right: 25px;
      &::before {
        content: '';
        position: absolute; top: 3px; right: -15px;
        width: 6px; height: 8px;
        background: url('#{$image}/navi-arrow.png');
      }
    }
  }
  &__home {
    width: 13px; height: 13px;
    background: url('#{$image}/navi-home.png');
  }
}




/* =======================
  content
======================= */
.lay-container{
  @include clearBoth('&');
  @include inner();
  margin-bottom: 150px;
}

// left
.lay-aside-left{
  float: left;
  width:270px;
}

.lay-lnb{
  @include listRest('ul');
  h2{
    font-size: 30px;text-align: center;padding-bottom: 9px;border-bottom: 1px solid #ccc;font-weight: 400;
  }
  a {
    display: block;
    position: relative;
    padding: 14px 10px;
    border-bottom: 1px solid #a1a1a1;
    color: #333;
    font-size: 16px;
    transition: .3s;
    &::before {
      content: ''; display: block;
      position: absolute; top: 20px; right: 10px;
      width: 9px; height: 9px;
      background: url('#{$image}/lay-lnb--off.png');
    }
  }
  .active{
    color: #2a78e1;
    a {
      border-bottom: 3px solid $color-point;
      color: $color-point;
      &::before {
        background: url('#{$image}/lay-lnb--on.png');
      }
    }
  }
}

// right
.lay-content{
  float: right;
  width:calc(100% - 311px);
}

.cont-title {
  position: relative;
  margin-bottom: 50px;
  &.v2{
    margin-top: 30px;
  }
  &::before {
    content: ''; display: block;
    position: absolute; bottom: -20px; left: 0;
    width: 100%; height: 2px;
    background: repeat-x url('#{$image}/con-title__line.png');
  }
  h3{font-size: 30px;color: #272727;margin: 0;padding: 0;line-height: 1em;}
}

.cont-title-desc{
  color: #f77e13;font-size: 14px;
  margin-bottom: 10px;
}

.cont-title-2{
  color: #1e5caf;font-size: 20px;line-height: 1;margin-bottom: 15px;
}

.cont-title-3{
  color:#1e5caf;font-size: 17px;line-height: 1;margin-bottom: 10px;
  font-weight: 400;
}



.content {margin-top: 100px;}

////////////// location
.lay-location{
  @include clearBoth('&');

  border-bottom:1px solid #dfdfdf;background: #fafafa;

  .location-home{
    float: left;width: 70px;height: 70px;font-size: 0;
    background: url('#{$image}/location_home.png') no-repeat center #969eab;
  }

  .location-list{float: left;width:calc(100% - 70px);}

  .location-list{
    > li:last-child > button{border-bottom:3px solid #4283db;}
  }

}

.location-list{
  @include listRest('&');

  a{text-decoration: none;color: #272727;}
  
  > li{
    float: left;position: relative;z-index: 1;box-sizing: border-box;
    border-right:1px solid #dfdfdf;

    ul{display: none;}
  }

  > li > button{
    background: none;border:0;font-size: 16px;color: #272727;height:70px;width: 400px;text-align: left;
    padding:0 30px;position: relative;
    i{
      width:20px;height:20px;position: absolute;right: 20px;top: calc(50% - 10px);
      background:url('#{$image}/location_arr.png');
      // background: red;
    }
  }

  > li > ul{
    margin: 0;padding: 0;
    left: 0;width: calc(100% - 1px);
    position: absolute;border: 1px solid #dfdfdf;
    background: #fff;

    li{
      border-top:1px solid #dfdfdf;
      &:first-child{border-top:0;}
    }

    a{display: block;padding:0 30px;height:70px;line-height: 70px;}
  }
}

.section{
  +.section{
    margin-top: 50px;
  }
}

@for $i from 1 through 120 {
  .width-#{$i*10}{
    width: #{$i*10}px !important;
  }
}

@for $i from 1 through 120 {
  .height-#{$i*10}{
    height: #{$i*10}px !important;
  }
}
.height-48{height: 48px !important;}


$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
