
.modal{
	&._none{
		position: static;
	}
	&-header{
		&-title{
			height: 70px;line-height: 70px;
			padding-left: 20px;
			background-color: #2f71c9;
			font-size: 30px;font-weight: 400;color: #fff;
		}
	}
	&-body{
		padding: 0 20px;
	}
}

.content-util{
	text-align: right;
	margin-top: 8px;
}